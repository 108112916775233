import React from 'react';
import './Loader.scss';
import { DominoSpinner } from 'react-spinners-kit';

export default function Loader() {
  return (
    <div className="loader">
      <DominoSpinner size={200} color="#fff" />
    </div>
  );
}
