/* eslint-disable no-console */
import { getData } from '../../Utilits/responses';
import sortArr from '../../Utilits/sortArr';

export const ACTIONS = {
  SET_WIDTH: 'SET_WIDTH',
  SET_TOKEN: 'SET_TOKEN',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_GLOBAL_INFO: 'SET_GLOBAL_INFO',
  SET_MAINPAGE_DATA: 'SET_MAINPAGE_DATA',
  SET_ABOUTPAGE_DATA: 'SET_ABOUTPAGE_DATA',
  SET_CONTACTSPAGE_DATA: 'SET_CONTACTSPAGE_DATA',
  SET_SERVICESPAGE_DATA: 'SET_SERVICESPAGE_DATA',
  SET_NEWSPAGE_DATA: 'SET_NEWSPAGE_DATA',
  SET_REVIEWSPAGE_DATA: 'SET_REVIEWSPAGE_DATA',
  SET_SELFNEWS_DATA: 'SET_SELFNEWS_DATA',
  SET_SERVICEPAGE_DATA: 'SET_SERVICEPAGE_DATA',
};

export const setWidth = (payload) => ({ type: ACTIONS.SET_WIDTH, payload });
export const setToken = (payload) => ({ type: ACTIONS.SET_TOKEN, payload });
export const setLanguage = (payload) => ({
  type: ACTIONS.SET_LANGUAGE,
  payload,
});

const setMainPageData = (payload) => ({
  type: ACTIONS.SET_MAINPAGE_DATA,
  payload,
});

const setGlobalInfo = (payload) => ({
  type: ACTIONS.SET_GLOBAL_INFO,
  payload,
});

const setAboutPageData = (payload) => ({
  type: ACTIONS.SET_ABOUTPAGE_DATA,
  payload,
});

const setContactsPageData = (payload) => ({
  type: ACTIONS.SET_CONTACTSPAGE_DATA,
  payload,
});

const setServicesPageData = (payload) => ({
  type: ACTIONS.SET_SERVICESPAGE_DATA,
  payload,
});

const setNewsPageData = (payload) => ({
  type: ACTIONS.SET_NEWSPAGE_DATA,
  payload,
});

const setReviewsPageData = (payload) => ({
  type: ACTIONS.SET_REVIEWSPAGE_DATA,
  payload,
});

const setSelfNewsPageData = (payload) => ({
  type: ACTIONS.SET_SELFNEWS_DATA,
  payload,
});

const setServicePageData = (payload) => ({
  type: ACTIONS.SET_SERVICEPAGE_DATA,
  payload,
});

export const getGlobalInfo = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/global_info`)
      .then((res) => {
        res.data.menu = sortArr(res.data.menu);
        dispatch(setGlobalInfo(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getMainPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/main`)
      .then((res) => {
        res.data.developer = sortArr(res.data.developer);
        res.data.оffice_service = sortArr(res.data.оffice_service);
        res.data.seo_text = sortArr(res.data.seo_text);
        res.data.location = sortArr(res.data.location);
        dispatch(setMainPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getAboutPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/about-us`)
      .then((res) => {
        res.data.notary = sortArr(res.data.notary);
        res.data.partners = sortArr(res.data.partners);
        dispatch(setAboutPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getContactsPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/contacts`)
      .then((res) => {
        res.data.partners = sortArr(res.data.partners);
        dispatch(setContactsPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getServicesPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/services`)
      .then((res) => {
        res.data.services = sortArr(res.data.services);
        dispatch(setServicesPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getNewsPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/news`)
      .then((res) => {
        dispatch(setNewsPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getReviewsPageData = (lang) => {
  return (dispatch) => {
    getData(`/api/${lang || 'uk'}/reviews`)
      .then((res) => {
        dispatch(setReviewsPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getSelfNewsPageData = (lang, id) => {
  return (dispatch) => {
    dispatch(setSelfNewsPageData(null));
    getData(`/api/${lang || 'uk'}/news/${id}`)
      .then((res) => {
        dispatch(setSelfNewsPageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};

export const getServicePageData = (lang, id) => {
  return (dispatch) => {
    dispatch(setServicePageData(null));
    getData(`/api/${lang || 'uk'}/services/${id}`)
      .then((res) => {
        dispatch(setServicePageData(res.data));
      })
      .catch((err) => console.error(err));
  };
};
