import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ACTIONS } from './actions';

const initialState = {
  lang: 'uk',
  width: 1440,
  token: null,
  globalInfo: null,
  mainPageData: null,
  aboutPageData: null,
  contactsPageData: null,
  servicesPageData: null,
  newsPageData: null,
  reviewsPageData: null,
  selfNewsPageData: null,
  servicePageData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_WIDTH:
      return { ...state, width: action.payload };
    case ACTIONS.SET_TOKEN:
      return { ...state, token: action.payload };
    case ACTIONS.SET_LANGUAGE:
      return { ...state, lang: action.payload };
    case ACTIONS.SET_GLOBAL_INFO:
      return { ...state, globalInfo: action.payload };
    case ACTIONS.SET_MAINPAGE_DATA:
      return { ...state, mainPageData: action.payload };
    case ACTIONS.SET_ABOUTPAGE_DATA:
      return { ...state, aboutPageData: action.payload };
    case ACTIONS.SET_CONTACTSPAGE_DATA:
      return { ...state, contactsPageData: action.payload };
    case ACTIONS.SET_SERVICESPAGE_DATA:
      return { ...state, servicesPageData: action.payload };
    case ACTIONS.SET_NEWSPAGE_DATA:
      return { ...state, newsPageData: action.payload };
    case ACTIONS.SET_REVIEWSPAGE_DATA:
      return { ...state, reviewsPageData: action.payload };
    case ACTIONS.SET_SELFNEWS_DATA:
      return { ...state, selfNewsPageData: action.payload };
    case ACTIONS.SET_SERVICEPAGE_DATA:
      return { ...state, servicePageData: action.payload };
    default:
      return state;
  }
};

export default createStore(reducer, applyMiddleware(thunk));
