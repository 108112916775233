/* eslint-disable no-console */
import React, { useEffect, Suspense, lazy } from 'react';
import $ from 'jquery';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWidth } from './store/actions';

// components
import Loader from './components/Loader/Loader';

const MainPage = lazy(() => import('./Pages/MainPage/MainPage'));
const AboutUs = lazy(() => import('./Pages/AboutUs/AboutUs'));
const ContactsPage = lazy(() => import('./Pages/ContactsPage/ContactsPage'));
const NewsPage = lazy(() => import('./Pages/NewsPage/NewsPage'));
const SelfNewsPage = lazy(() => import('./Pages/SelfNewsPage/SelfNewsPage'));
const ServicesPage = lazy(() => import('./Pages/ServicesPage/ServicesPage'));
const ServicePage = lazy(() => import('./Pages/ServicePage/ServicePage'));
const ReviewsPage = lazy(() => import('./Pages/ReviewsPage/ReviewsPage'));

function App() {
  const dispatch = useDispatch();

  const handleResize = () => {
    dispatch(setWidth($('body').width()));
  };

  useEffect(() => {
    dispatch(setWidth($('body').width()));
    window.addEventListener('resize', handleResize);

    // getToken()
    //   .then((token) => dispatch(setToken(token)))
    //   .catch((err) => console.error(err));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Suspense fallback={Loader}>
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/contacts" exact component={ContactsPage} />
        <Route path="/news" exact component={NewsPage} />
        <Route path="/news/:id" exact component={SelfNewsPage} />
        <Route path="/services" exact component={ServicesPage} />
        <Route path="/services/:id" exact component={ServicePage} />
        <Route path="/reviews" exact component={ReviewsPage} />

        <Route path="/:lang/" exact component={MainPage} />
        <Route path="/:lang/about-us" exact component={AboutUs} />
        <Route path="/:lang/contacts" exact component={ContactsPage} />
        <Route path="/:lang/news" exact component={NewsPage} />
        <Route path="/:lang/news/:id" exact component={SelfNewsPage} />
        <Route path="/:lang/services" exact component={ServicesPage} />
        <Route path="/:lang/services/:id" exact component={ServicePage} />
        <Route path="/:lang/reviews" exact component={ReviewsPage} />
      </Switch>
    </Suspense>
  );
}

export default App;
