export const getData = async (url, token) => {
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.json();
};

export const getToken = async () => {
  const res = await fetch('/api/login?email=oleg@gmail.com&password=Test1234', {
    method: 'POST',
  });
  return res.json();
};

export const postData = async (data, url, token) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': token,
    },
    body: JSON.stringify(data),
  });
  return res.json();
};
